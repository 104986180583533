import React from 'react';
import { Helmet } from 'react-helmet';

import './App.scss';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.title = 'SitePartner - Website Development';
        this.description = 'SitePartner providing high quality, affordable and modern websites. Building beautiful and effective websites that stand out from your competition and bring more clients.';
        this.url = window.location.protocol + '//' + window.location.host;
    }

    render() {
        return (
            <div className="container-fluid">
                <Helmet>
                    <title>{this.title}</title>
                    <meta name="title" content={this.title} />
                    <meta name="description" content={this.description} />
                    {/* Open Graph / Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={this.url} />
                    <meta property="og:title" content={this.title} />
                    <meta property="og:description" content={this.description} />
                    <meta property="og:image" content={this.url + '/images/logo.svg'} />

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={this.url} />
                    <meta property="twitter:title" content={this.title} />
                    <meta property="twitter:description" content={this.description} />
                    <meta property="twitter:image" content={this.url + '/images/logo.svg'} />
                </Helmet>
                <div className="row">
                    <div className="col-lg-6 px-0 order-lg-2">
                        <div className="sticky-top vh-lg-100 py-9">
                            <div
                                className="bg-holder"
                                style={{
                                    backgroundImage: 'url(/images/bg.jpg)',
                                    opacity: 1,
                                    transform: 'translate(0px, 0px)',
                                    filter: 'brightness(80%)',
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="col-lg-6 py-6">
                        <div className="row h-100 d-flex align-items-center justify-content-center">
                            <div className="col-sm-11 col-md-8 col-lg-11 col-xl-9 px-xl-5">
                                <div className="mb-5 mb-lg-7 overflow-hidden">
                                    <img
                                        src="/images/logo.svg"
                                        height="30"
                                        alt={this.title}
                                    />
                                </div>
                                <div className="d-flex mb-5">
                                    <div className="me-3 me-sm-4">
                                        <i className="fas fa-laptop-code text-dark fa-3x"></i>
                                    </div>
                                    <div className="flex-1">
                                    <div className="overflow-hidden">
                                        <h4 className="mb-2">Website Development</h4>
                                    </div>
                                    <div className="overflow-hidden">
                                        <p className="mb-0">Your website is your representation in the online world. Building beautiful and effective website that stand out from your competition and bring more clients.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-5">
                                    <div className="me-3 me-sm-4">
                                        <i className="fas fa-cogs text-dark fa-3x"></i>
                                    </div>
                                    <div className="flex-1">
                                    <div className="overflow-hidden">
                                        <h4 className="mb-2">SEO & Custom Solutions</h4>
                                    </div>
                                    <div className="overflow-hidden">
                                        <p className="mb-0">Will optimize your website so that it is more search engine friendly with research, structure, and unique content. Always ready to build solutions specially developed for your.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-5">
                                    <div className="me-3 me-sm-4">
                                        <i className="fas fa-sync-alt text-dark fa-3x"></i>
                                    </div>
                                    <div className="flex-1">
                                    <div className="overflow-hidden">
                                        <h4 className="mb-2">Ongoing Support & Updates</h4>
                                    </div>
                                    <div className="overflow-hidden">
                                        <p className="mb-0">We are happy to help you with any questions. Websites always updated & secure.</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;